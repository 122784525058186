import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import Backend from "i18next-chained-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import LocalStorageBackend from "i18next-localstorage-backend";
import { supportedLngs } from '../utils/languages';

i18next
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(
    {
      ns: ["navbar"],
      // lng: "ro",
      supportedLngs,
      fallbackLng: "ro",
      nonExplicitSupportedLngs: true,
      saveMissing: true,
      interpolation: {
        escapeValue: false,
      },
      debug: true,
      backend: {
        backends: [HttpApi, LocalStorageBackend],
        backendOptions: [
          {
            expirationTime: 7 * 24 * 60 * 60 * 1000 // 7 days
          },
          {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
          },
        ],
      },
    },
  );

export default i18next;
