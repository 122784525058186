import React from 'react';
import logo from'./logo.svg';

const Logo: React.FC = () => {
    return (
        <a className="navbar-item" href="/">
            <img src={logo} alt="Danvic" height={ 28 }/>
        </a>
    )
};

export default Logo;