import React from 'react';
import Navbar from '../Navbar/Navbar';

const AppHeader: React.FC = () => {
  return (
    <header className="AppHeader">
      <Navbar />
    </header>
)
};

export default AppHeader;