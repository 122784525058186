import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  return (
    <div className="select is-primary is-small mt-3 is-size-6-mobile is-size-6-tablet is-size-7-desktop">
      <select
        value={i18n.resolvedLanguage}
        onChange={(e) => i18n.changeLanguage(e.target.value)}
      >
        <option value="en">EN</option>
        <option value="ro">RO</option>
        <option value="ru">RU</option>
      </select>
    </div>
  );
};
export default LanguageSwitcher;
