import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./containers/App/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./common/services/i18n";

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
        </Routes>
      </Router>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
