import React, { createRef } from "react";
import Logo from "../Logo/Logo";
import MenuItem from "../MenuItem/MenuItem";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../LanguageSwitcher";

const Navbar: React.FC = () => {
  const { t } = useTranslation();
  const burgerMenuButton = createRef<HTMLButtonElement>();
  const menu = createRef<HTMLDivElement>();

  const toggleMenu = () => {
    burgerMenuButton.current?.classList.toggle("is-active");
    menu.current?.classList.toggle("is-active");
  };

  return (
    <nav
      className="navbar is-fixed-top has-shadow"
      aria-label="Main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Logo />

          <button
            ref={burgerMenuButton}
            role="button"
            className="navbar-burger"
            aria-label="Main menu"
            aria-expanded="false"
            onClick={toggleMenu}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </button>
        </div>

        <div ref={menu} className="navbar-menu has-text-centered">
          <div className="navbar-end">
            <MenuItem title={t("services", "Services", {ns: "navbar"})} linkUrl="/services" />
            <MenuItem title={t("news", "News", {ns: "navbar"})} linkUrl="/news" />
            <MenuItem title={t("contacts", "Contacts", {ns: "navbar"})} linkUrl="/contacts" />
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
