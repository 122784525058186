import React, { FC } from "react";
import { Link } from "react-router-dom";

interface LinkProps {
  title: string;
  linkUrl: string;
}

const MenuItem: FC<LinkProps> = ({ title, linkUrl }) => {
  return (
    <Link
      className="navbar-item is-size-5-mobile is-size-5-tablet is-size-6-desktop is-uppercase has-text-weight-medium"
      to={linkUrl}
    >
      {title}
    </Link>
  );
};

export default MenuItem;
